<app-card
  header="Atención y adquisición del cliente"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <deuna-input
    [masked]="false"
    styleClass="mt-4"
    [form]="formCN019"
    label="Correo para atención en Zendesk"
    [maxLength]="50"
    placeholder="Ej: micorreo@mail.com"
    name="zendeskEmail"
    control="zendeskEmail"
  ></deuna-input>
  <ng-container
    *ngIf="
      (isEditable && !isFlowUpdate) || enableEdit;
      else editSourceChannel
    "
  >
    <deuna-radio-button
      label="Canal de adquisición del negocio"
      styleClassItem="pt-2"
      [values]="sourceChannels"
      control="sourceChannel"
      [form]="formCN019"
    >
    </deuna-radio-button>
  </ng-container>
  <ng-template #editSourceChannel>
    <deuna-input
      [masked]="false"
      styleClass="mt-4"
      [form]="formCN019"
      label="Canal de adquisición del negocio"
      name="sourceChannel"
      control="sourceChannel"
    ></deuna-input>
  </ng-template>

  <ng-container
    *ngIf="hasProvider"
  >
    <deuna-input
      [masked]="false"
      styleClass="mt-4"
      [form]="formCN019"
      label="Nombre de proveedor"
      [maxLength]="50"
      placeholder="Ej: proveedor y"
      name="sourceProvider"
      control="sourceProvider"
    ></deuna-input>
  </ng-container>
</app-card>
