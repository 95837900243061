import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription, debounceTime, lastValueFrom } from 'rxjs';
import { optionsTypesSend } from 'src/app/constants/catalogos/mocks-catalogo';
import { clientRoute } from 'src/app/constants/routes';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { GraphqlReportService } from 'src/app/services/graphql/graphql-report.service';
import { MerchantStateService } from 'src/app/services/state/merchant-state.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import {
  IDeleteReport,
  IQueryBranchReport,
  IReportWithBrachName,
} from 'src/app/shared/interfaces/query-reports';

@Component({
  selector: 'app-list-branch-reports',
  templateUrl: './list-branch-reports.page.html',
  styleUrls: ['./list-branch-reports.page.scss'],
})
export class ListBranchReportsPage implements OnInit, OnDestroy {
  relativeRoute = clientRoute;
  reportList: IReportWithBrachName[] = [];
  loadPage = true;
  loadList = true;
  formSearch!: FormGroup;
  reportId: string | null = null;
  merchantId: string | null = null;
  reportType: string | null = null;
  currentPage: number = 1;
  subActivatedRoute!: Subscription;
  inputSub!: Subscription;
  merchantStateSub!: Subscription;
  merchantState!: any;
  valueChanged: any;
  limitPage: number = 7;
  itemCount: number = 0;
  totalItems: number = 0;
  itemsPerPage: number = 0;
  totalPages: number = 0;
  showDeleteModal: boolean = false;
  reportToDelete: IReportWithBrachName | undefined;

  headers = [
    'Nombre del Sucursal',
    'Id de sucursal',
    'Frecuencia en horas',
    'Correo 1',
    'Correo 2',
    '',
  ];

  paramsQuery: IQueryBranchReport = {
    page: this.currentPage,
    limit: this.limitPage,
    reportId: '',
    search: '',
  };

  listReportsResultfields: string = `
  id
  branchName
  branchId,
  daysFrequency
  mails
  status
  type
  `;

  getMerchantResultFields: string = `id,
  identification
  comercialName
  businessName
  isComplete
  status
  cifAccount`;

  dataPagination = {
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: 7,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private router: Router,
    private gqlReportService: GraphqlReportService,
    private graphqlMerchantService: GraphqlMerchantService,
    private merchantStateService: MerchantStateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.initializePage();
    this.subscribeToMerchantInfo();
    this.formSearch = this.fb.group({
      textSearch: [''],
    });

    this.loadInformation();
  }

  private async loadInformation() {
    if (!this.merchantState && this.merchantId) {
      await this.getMerchant();
    }

    if (this.reportId) {
      await this.getReports();
      this.reportType = optionsTypesSend.find(
        (x: ICatalogue) => x.code === this.reportList[0].type
      )?.name!;
    }
    this.loadPage = false;
  }

  private initializePage() {
    const queryParams = this.activatedRoute.snapshot.params;
    this.reportId = queryParams['reportId'];
    this.merchantId = queryParams['merchantId'];

    if (this.reportId) {
      this.paramsQuery = {
        page: this.currentPage,
        limit: this.limitPage,
        reportId: this.reportId,
        search: '',
      };
    }
  }

  private subscribeToMerchantInfo() {
    this.merchantStateSub = this.merchantStateService.merchant$.subscribe(
      (item) => {
        this.merchantState = item;
      }
    );
  }

  private async getMerchant() {
    if (this.merchantId) {
      const getMerchantByIdRequest =
        this.graphqlMerchantService.getMerchantById(
          this.merchantId,
          this.getMerchantResultFields
        );
      const merchant = (await lastValueFrom(getMerchantByIdRequest)).data
        .getClient;
      this.merchantStateService.setMerchant(merchant);
    }
  }

  ngOnDestroy(): void {
    this.merchantStateSub.unsubscribe();
  }

  ngAfterContentInit() {
    this.suscribeToInputChanges();
  }

  suscribeToInputChanges() {
    this.inputSub = this.formSearch.controls['textSearch'].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value) => {
        this.paramsQuery.page = 1;
        this.paramsQuery.search = value;
        this.getReports();
      });
  }

  handleShowDeleteModal(item: IReportWithBrachName) {
    this.showDeleteModal = true;
    this.reportToDelete = item;
  }

  hideDeleteModal() {
    this.showDeleteModal = false;
  }

  deleteReport() {
    if (!this.reportToDelete) return;
    this.loadList = true;
    const deletedReport: IDeleteReport = {
      id: this.reportToDelete.id,
    };
    this.gqlReportService.deleteReport(deletedReport).subscribe({
      next: () => {
        this.viewToast();
        this.getReports();
        this.loadList = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async getReports() {
    this.loadList = true;
    const getBranchReportsReportsReq =
      this.gqlReportService.getBranchReportsReports(
        this.paramsQuery,
        this.listReportsResultfields
      );

    try {
      const getBranchReportsReportsRes = await lastValueFrom(
        getBranchReportsReportsReq
      );
      const { items, meta } = getBranchReportsReportsRes.data.listBranchReports;
      if (items.length > 0) {
        this.dataPagination = meta;
      } else {
        this.dataPagination = {
          currentPage: 0,
          totalItems: 0,
          totalPages: 0,
          itemsPerPage: 7,
        };
      }
      this.reportList = items.filter((item) => item.status === 'ACTIVE');
      this.loadList = false;
    } catch (error) {
      console.log(error);
      this.router.navigate([`${clientRoute}/info`]);
    }
  }

  viewToast() {
    this.messageService.add({
      key: 'br',
      severity: 'deuna',
      summary: 'Eliminado exitosamente',
      closable: false,
    });
    this.loadList = false;
  }

  goToPageTable(page: number) {
    this.paramsQuery.page = page;
    this.getReports();
  }

  changeToItemsTable(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getReports();
  }

  redirectEdit(reportId: string) {
    this.router.navigate([`${this.relativeRoute}/report/update/${reportId}`]);
  }
}
