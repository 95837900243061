import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { optionsTypeNotificationGeneral } from 'src/app/constants/catalogos/mocks-catalogo';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
@Component({
  selector: 'app-card-notification',
  templateUrl: './card-notification.component.html',
  styleUrls: ['./card-notification.component.scss'],
})
export class CardNotificationComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  @Input() isCreationFlow: boolean = false;
  formCN006!: FormGroup;
  formCN005!: FormGroup;
  formConfigData!: IFormGroup;
  enableEdit: boolean = false;
  loading: boolean = false;
  labelSumbit: string = 'Guardar';
  trash: string = assetUrl('svg/trash.svg');
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();

  isMasked!: boolean[];

  constructor(
    private configDataService: ConfigurationDataService,
    private readonly fb: FormBuilder
  ) {}

  notifications: Array<ICatalogue> = [];
  notification: string = '';
  webhook: boolean = false;

  ngOnInit(): void {
    this.initData();
    if (this.isCreationFlow) this.handleEvent(true);
  }

  initData() {
    this.notifications = optionsTypeNotificationGeneral;
    this.formConfigData = this.configDataService.getForm();
    this.formCN005 = this.formConfigData.CN005;
    this.formCN006 = this.formConfigData.CN006;
    this.webhook = this.formCN006.controls['webhook']?.value;
    this.fillNotification();
  }

  fillNotification() {
    const itemNotification: string[] = [];
    this.notifications.forEach((item) => {
      if (this.formCN006.controls[item.code]?.value) {
        itemNotification.push(item.name);
      }
    });
    this.notification = itemNotification.join(',');
    this.notification = itemNotification.join(',');
  }

  get webhookHeadersFields(): FormArray {
    return this.formCN005.get('webhookHeaders') as FormArray;
  }

  hasError(i: number, error: string, field: string): boolean {
    return this.webhookHeadersFields.controls[i].get(field)!.hasError(error);
  }

  isValidField(i: number, field: string) {
    return this.webhookHeadersFields.controls[i].get(field)!.invalid;
  }

  isTouched(i: number, field: string): boolean {
    return this.webhookHeadersFields.controls[i].get(field)!.touched;
  }

  addRow() {
    if (this.webhookHeadersFields.length === 10) return;
    const row = this.fb.group({
      key: ['', [Validators.required]],
      value: ['', [Validators.required]],
    });
    this.webhookHeadersFields.push(row);
  }

  removeRow(index: number) {
    this.webhookHeadersFields.removeAt(index);
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN006.disable();
    this.formCN005.controls['webhookUrl'].disable();
    this.formCN005.controls['webhookHeaders']?.disable();
    this.formCN005.controls['webhookEmail']?.disable();
  }

  onSubmit() {
    this.loading = true;
    if (this.webhook) {
      this.formCN005.controls['webhookUrl'].setValidators([
        Validators.required,
      ]);
    } else {
      this.formCN005.controls['webhookUrl'].clearValidators();
      this.formCN005.controls['webhookUrl'].setValue('');
      this.formCN005.controls['webhookVersion'].setValue('');
      this.formCN005.controls['webhookEmail']?.setValue('');
    }
    this.formCN005.controls['webhookUrl'].updateValueAndValidity();
    this.formCN005.markAllAsTouched();
    if (!this.formCN005.valid || !this.formCN006.valid) {
      this.loading = false;
      return;
    }
    Promise.allSettled([
      this.configDataService.updateCN005Configuration(),
      this.configDataService.updateConfiguration('CN006'),
    ])
      .then(() => {
        this.fillNotification();
        this.emitToast();
        this.loading = false;
        this.enableEdit = false;
        this.formCN006.disable();
        this.formCN005.controls['webhookUrl'].disable();
        this.formCN005.controls['webhookHeaders']?.disable();
        this.formCN005.controls['webhookEmail']?.disable();
      })
      .catch((err) => console.error({ err }));
  }

  handleEvent(data) {
    if (data) {
      this.enableEdit = true;
      this.formCN006.enable();
      this.formCN005.controls['webhookUrl'].enable();
      this.formCN005.controls['webhookHeaders']?.enable();
      this.formCN005.controls['webhookEmail']?.enable();
    }
  }

  onChange() {
    this.webhook = this.formCN006.controls['webhook']?.value;
  }

  toggleMasked(i) {
    const inputElement: HTMLInputElement = document.getElementById(
      `value${i}`
    ) as HTMLInputElement;
    const maskElement: HTMLElement = document.getElementById(
      `mask${i}`
    ) as HTMLElement;
    if (inputElement) {
      inputElement.type =
        inputElement.type === 'password' ? 'string' : 'password';
      maskElement.textContent =
        maskElement.textContent === 'visibility'
          ? 'visibility_off'
          : 'visibility';
    }
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
