import { Component, Input, OnInit } from '@angular/core';
import { ICatalogue } from '../../../../shared/interfaces/calatogue.interface';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  optionsTypesQr,
  defaultTypeQr,
  optionsSizesQr,
  defaultSizeQr,
} from 'src/app/constants/catalogos/mocks-catalogo';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { MerchantItem } from '../../../../shared/interfaces/query-merchants';
import { Branch } from 'src/app/shared/interfaces/query-branchs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-view-qr-side',
  templateUrl: './view-qr-side.component.html',
  styleUrls: ['./view-qr-side.component.scss'],
})
export class ViewQrSideComponent implements OnInit {
  @Input() merchant!: MerchantItem;
  @Input() branch: Branch | null | undefined = null;
  imagenQr: string = assetUrl('png/qr_fondo.png');
  formGroup!: FormGroup;
  optionstypesQr: Array<ICatalogue> = optionsTypesQr;
  defaultTypeQr: ICatalogue = defaultTypeQr;
  sizesQr: Array<ICatalogue> = optionsTypesQr;
  optionsSizesQr: Array<ICatalogue> = optionsSizesQr;
  defaultSizeQr: ICatalogue = defaultSizeQr;
  downloadBand: boolean = false;

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private readonly fb: FormBuilder,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initConf();
  }

  initConf() {
    this.formGroup = this.fb.group({
      typeQr: [this.defaultTypeQr, [Validators.required]],
      sizeQr: [null],
      templateName: [null, [Validators.required]],
      dataTemplate: [null, [Validators.required]],
      type: [null],
      merchantId: [null],
      comercialName: [null],
      branch: [null],
    });
    this.formGroup.get('typeQr')?.setValue(this.defaultTypeQr.code);
    this.formGroup
      .get('branch')
      ?.setValue(this.branch?.name ?? 'Todos los QRs del negocio');
    this.formGroup.get('branch')?.disable();
    this.formGroup.get('templateName')?.setValue('templateBaseFondo.html');
  }

  changeImagen() {
    if (this.formGroup.controls['typeQr'].value === 'QRSP') {
      this.formGroup.get('sizeQr')?.setValue(this.defaultSizeQr.code);
      this.imagenQr = assetUrl('png/qr_sin_fondo_5x5.png');
      this.formGroup.get('templateName')?.setValue('templateBase5x5.html');
    } else {
      this.imagenQr = assetUrl('png/qr_fondo.png');
      this.formGroup.get('sizeQr')?.setValue(null);
      this.formGroup.get('templateName')?.setValue('templateBaseFondo.html');
    }
  }
  successToast() {
    this.messageService.add({
      key: 'global',
      severity: 'deuna',
      summary: 'Envio exitoso. Revisa tu correo',
      closable: false,
    });
  }

  errorToast() {
    this.messageService.add({
      key: 'global',
      severity: 'error',
      summary: 'Envio de PDF fallido',
      closable: false,
      icon: '',
    });
  }

  changeSize() {
    if (this.formGroup.controls['sizeQr'].value === '5X5') {
      this.imagenQr = assetUrl('png/qr_sin_fondo_5x5.png');
      this.formGroup.get('templateName')?.setValue('templateBase5x5.html');
    } else {
      this.imagenQr = assetUrl('png/qr_sin_fondo_10x10.png');
      this.formGroup.get('templateName')?.setValue('templateBase10x10.html');
    }
  }

  generationPdf() {
    let type = 'merchant';
    this.formGroup.get('dataTemplate')?.setValue(
      JSON.stringify({
        merchantId: this.merchant.id,
        comercialName: this.merchant.comercialName,
      })
    );

    if (this.branch) {
      type = 'branch';
      this.formGroup.get('dataTemplate')?.setValue(
        JSON.stringify({
          merchantId: this.merchant.id,
          comercialName: this.merchant.comercialName,
          branchId: Number(this.branch.id),
          branchName: this.branch.name,
          posIdList: this.branch.POSList.map((item) => item.id),
        })
      );
    }

    this.formGroup.get('type')?.setValue(type);
    this.downloadBand = true;
    const tmpData = this.formGroup.value;
    delete tmpData.sizeQr;
    delete tmpData.typeQr;
    delete tmpData.comercialName;
    delete tmpData.merchantId;
    delete tmpData.branch;
    this.graphqlMerchantService.createPdf(this.formGroup.value).subscribe({
      next: (res) => {
        if (res.data?.createPdf.status !== 'success') {
          this.errorToast();
          this.downloadBand = false;
          return;
        }
        this.successToast();
        this.downloadBand = false;
      },
      error: () => {
        this.errorToast();
        this.downloadBand = false;
      },
    });
  }
}
