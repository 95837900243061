import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { defaultCountry } from '../../../constants/catalogos/mocks-catalogo';
import {
  DEFAULT_BANK,
  DEFAULT_COUNTRY,
  REGEX_WITHOUT_SPECIAL_CHARACTERS,
} from '../../../constants/merchant-constants';
import { customFormValid } from '../../../shared/validations/custom-form-valid';
import {
  IFormGroup,
  WebhookHeaders,
} from '../../../shared/interfaces/form-group.interface';
import { form000, formSearch } from 'src/app/shared/utils/forms-control';

@Injectable()
export class ConfigurationFormService {
  formGroup!: IFormGroup;
  regexWithoutSpecialCharacters = /^[0-9a-zA-ZñÑáéíóúÁÉÍÓÚ&.,\-:"'{}\[\] ]*$/;

  constructor(private readonly fb: FormBuilder) {}

  createForm() {
    const form: IFormGroup = {
      search: this.fb.group(formSearch),
      checkAssociation: this.fb.group({
        hasGroup: ['', Validators.required],
      }),

      CN000: this.fb.group(form000),
      associationGroup: this.fb.group({
        commercialGroupId: [''],
      }),
      CN001: this.getFormCN001(),
      CN002: this.getFormCN002(),
      CN003: this.getFormCN003(),
      CN004: this.getFormCN004(),
      CN005: this.getFormCN005(),
      CN006: this.getFormCN006(),
      CN010: this.getFormCN010(),
      CN012: this.getFormCN012(),
      CN013: this.getFormCN013(),
      CN015: this.getFormCN015(),
      CN016: this.getFormCN016(),
      CN019: this.getFormCN019(),
    };
    return form;
  }

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }

    this.formGroup = this.createForm();
    return this.formGroup;
  }

  getFormCN001() {
    return this.fb.group({
      legalRepresentative: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
        ],
      ],
    });
  }

  getFormCN002() {
    return this.fb.group({
      category: [null, [Validators.required]],
      subcategory: [null, [Validators.required]],
      businessType: [null, [Validators.required]],
      segment: [null, [Validators.required]],
    });
  }

  getFormCN003() {
    return this.fb.group({
      accountNumber: [null, [Validators.required]],
      bankName: [DEFAULT_BANK, [Validators.required]],
      accountType: [null, [Validators.required]],
      identificationNumber: [''],
    });
  }

  getFormCN004() {
    return this.fb.group({
      country: [DEFAULT_COUNTRY],
      region: [null, [Validators.required]],
      province: [null, [Validators.required]],
      canton: [null, [Validators.required]],
      streetPrincipal: [null, [Validators.required]],
      streetSecondary: [
        null,
        [Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
      ],
      houseNumber: [
        null,
        [Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
      ],
    });
  }

  getFormCN005() {
    return this.fb.group({
      region: [null],
      phoneCode: [defaultCountry, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      emailMerchant: [null, [Validators.required, Validators.email]],
      webhookUrl: [null],
      webhookVersion: ['V2'],
      webhookHeaders: this.fb.array([]),
      webhookEmail: [null, [Validators.email]],
    });
  }

  setFormHeadersCN005(headers: WebhookHeaders[] | undefined) {
    (this.formGroup.CN005.get('webhookHeaders') as FormArray).clear();
    headers?.forEach((header) => {
      const row = this.fb.group({
        key: [header.key, [Validators.required]],
        value: [header.value, [Validators.required]],
      });
      (this.formGroup.CN005.get('webhookHeaders') as FormArray).push(row);
    });
  }

  getFormCN006() {
    return this.fb.group(
      {
        email: [false],
        sms: [false],
        webhook: [false],
      },
      {
        validators: customFormValid,
      }
    );
  }

  getFormCN010() {
    return this.fb.group({
      optionBranch: [null, [Validators.required]],
      optionExcel: [null],
      nameMerchatContact: [null],
      mailMerchantContact: [null, [Validators.email]],
    });
  }
  getFormCN012() {
    return this.fb.group({
      accountType: [null, [Validators.required]],
      accountNumber: [null, [Validators.required]],
      identificationNumber: [''],
      bankName: [DEFAULT_BANK, [Validators.required]],
    });
  }

  getFormCN013() {
    return this.fb.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }

  getFormCN015() {
    return this.fb.group({
      permissionGrantingDate: [null, [Validators.required]],
    });
  }

  getFormCN016() {
    return this.fb.group({
      taxRegime: [null, null],
    });
  }

  getFormCN019() {
    return this.fb.group({
      webhookEmail: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      sourceChannel: [null, [Validators.required]],
      sourceProvider: [
        null,
        [
          Validators.maxLength(50),
          Validators.pattern(this.regexWithoutSpecialCharacters),
        ],
      ],
    });
  }
}
