import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { clientRoute } from '../../../../../constants/routes';
import { IFormGroupReport } from '../../../../../shared/interfaces/form-group.interface';
import { ConfigurationFlowReportDataService } from '../../../../../services/config/report/configuration-flow-data-report.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.page.html',
  styleUrls: ['./update.page.scss'],
})
export class UpdatePage implements OnInit {
  relativeRoute = clientRoute;
  subActivatedRoute!: Subscription;
  reportId!: string;
  merchantId!: string;
  formReportData!: IFormGroupReport;
  loadData: boolean = true;
  isEditable: boolean = false;
  isEditableEmails: boolean = true;
  reportName: string = 'Reporte de ventas';
  levelReport: string = '';
  typeReport: string = '';
  isUnifiedReport!: boolean;
  maximumAllowedEmails!: number;
  maxAllowedEmailsUnified = 10;
  maxAllowedEmailsByBranch = 2;
  constructor(
    private activatedRoute: ActivatedRoute,
    private configurationFlowReportDataService: ConfigurationFlowReportDataService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.reportId = this.getReportIdFromParams() as string;
    await this.configurationFlowReportDataService.setFormDataReport(
      this.reportId
    );
    this.formReportData = this.configurationFlowReportDataService.getForm();
    this.merchantId = this.formReportData.initial.controls['merchantId']?.value;
    this.reportName = this.formReportData.initial.controls['nameReport']?.value;
    const levelValue = this.formReportData.initial.controls['level']?.value;
    const typeValue = this.formReportData.initial.controls['type']?.value;
    this.levelReport = levelValue === 'BRANCH' ? 'Por sucursal' : 'Unificado';
    this.isUnifiedReport = this.isUnified();
    this.maximumAllowedEmails = this.isUnified() ? this.maxAllowedEmailsUnified : this.maxAllowedEmailsByBranch;
    this.typeReport =
      typeValue === 'SALES' ? 'Reporte de ventas' : 'Reporte de conciliación';
    this.loadData = false;
  }
  getReportIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('reportId');
  }

  private isUnified() {
    return this.levelReport === 'Unificado';
  }
}
