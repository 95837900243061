import {
  IQueryBranch,
  ResponseBranch,
  ResponseCreateBranch,
  ResponseCreatePos,
  ResponseUploadFile,
} from '../../shared/interfaces/query-branchs';
import {
  ParamsNodeByClientId,
  ResponseCodeCif,
  ResponseMerchant,
  ResponseMerchants,
  ResponseNodeByClientId,
  IQueryMerchant,
  ResponseMessage,
  InputUpdateClient,
  IQueryGroupByClient,
  ResponseCreateMerchantAD,
  ResponseMerchantsInCommercialGroup,
  IQueryGroupResult,
  IQueryGroup,
  ISearchGroupResult,
  IMutatioAssociateGroupToMerchant,
  IMutatioAssociateGroupToMerchantResult,
} from '../../shared/interfaces/query-merchants';
import {
  IDefaultPdf,
  ResponseCreatePdf,
} from '../../shared/interfaces/generation-template-pdf.interface';
import {
  CreateAllConfiguration,
  IClient,
  IDataConfig,
  IHierarchy,
  INodeQuantity,
  IDefaultConfig,
  Configurations,
  CreateClientADInput,
} from '../../shared/interfaces/config-merchant.interfaces';
import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql, MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GenerateDeeplinksBookFileInput,
  IResponseBranchConfiguration,
  IResponseConfiguration,
  NodeConfiguration,
} from 'src/app/shared/interfaces/query-configuration';
import { Observable } from 'rxjs';
import {
  IQueryBranchReport,
  IResponseBranchReports,
} from 'src/app/shared/interfaces/query-reports';

@Injectable()
export class GraphqlMerchantService {
  public apollo: ApolloBase;
  private idNodeMerchant: string = '';
  private idNodebranch: string = '';

  get idNodeMerchantGet() {
    return this.idNodeMerchant;
  }

  set idNodeMerchantSet(value: string) {
    this.idNodeMerchant = value;
  }

  get idNodebranchGet() {
    return this.idNodebranch;
  }

  set idNodebranchSet(value: string) {
    this.idNodebranch = value;
  }

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  getBpClientData(
    input: IClient,
    outPutResult: string
  ): Observable<ApolloQueryResult<ResponseCodeCif>> {
    return this.apollo.query({
      query: gql`
        query getTransactionalContact($input: GetTransactionalContact!){
          getTransactionalContact(GetTransactionalContact: $input) {
            ${outPutResult}
          }
        }`,
      variables: {
        input,
      },
    });
  }

  createMerchant(
    input: CreateClientADInput
  ): Observable<MutationResult<ResponseCreateMerchantAD>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createMerchant($input: CreateClientArgs!) {
          createMerchant(createClientArgs: $input) {
            clientId
            nodeId
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  createHierarchy(
    data: IHierarchy
  ): Observable<MutationResult<ResponseCreateBranch>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createNodeHierachy($data: CreateNodeHierachyData!) {
          createNodeHierachy(createNodeHierachyData: $data) {
            id
            nodeType
            temporalError {
              reason
              details
            }
          }
        }
      `,
      variables: {
        data,
      },
    });
  }

  createConfiguration(
    input: IDataConfig
  ): Observable<MutationResult<CreateAllConfiguration>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createAllConfiguration($input: InputAllConfiguration!) {
          createAllConfiguration(createAllConfiguration: $input) {
            message
            configurations {
              id
              nodeId
              configName
              configData
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  createUniqueConfiguration(
    input: IDefaultConfig
  ): Observable<MutationResult<Configurations>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createConfiguration($input: InputConfiguration!) {
          createConfiguration(createConfiguration: $input) {
            id
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  createPOSBranch(
    data: INodeQuantity
  ): Observable<MutationResult<ResponseCreatePos>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createChildrenNodeByQuantity(
          $data: CreateChildrenNodeByQuantityInput!
        ) {
          createChildrenNodeByQuantity(
            createChildrenNodeByQuantityInput: $data
          ) {
            status
            message
            temporalError {
              reason
              details
            }
          }
        }
      `,
      variables: {
        data,
      },
    });
  }

  getMerchantsPage(
    pagInput: IQueryMerchant,
    outPutResult: string
  ): Observable<ApolloQueryResult<ResponseMerchants>> {
    return this.apollo.query({
      query: gql`
        query getAllClients($pagInput: FilteringInput!){
          getAllClients(FilteringInput:$pagInput) {
            items {
              ${outPutResult}
            }
            meta{
              itemCount
              totalItems
              itemsPerPage
              totalPages
              currentPage
            }
          }
        }
      `,
      variables: {
        pagInput,
      },
    });
  }

  getGroupsPage(
    pagInput: IQueryGroup,
    outPutResult: string
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: gql`
        query findAllCommercialGroups($pagInput: FilterConfigInput!) {
          findAllCommercialGroups(FilterConfigInput: $pagInput) {
            items {
              nodeId,
              configData {
                ${outPutResult}
              },
              status
            }
            meta {
              itemCount
              totalItems
              itemsPerPage
              totalPages
              currentPage
            }
          }
        }
      `,
      variables: {
        pagInput,
      },
    });
  }

  getMerchantsByGroupNodeId(
    pagInput: IQueryGroup,
    outPutResult: string
  ): Observable<ApolloQueryResult<ResponseMerchantsInCommercialGroup>> {
    return this.apollo.query({
      query: gql`
        query getAllMerchantsByCommercialGroups(
          $pagInput: FilteringInputGroup!
        ) {
          getAllMerchantsByCommercialGroups(FilteringInputGroup: $pagInput) {
            items {
              ${outPutResult}
            }
          }
        }
      `,
      variables: {
        pagInput,
      },
    });
  }

  getBranchs(
    pagInput: IQueryBranch,
    outPutResult: string
  ): Observable<ApolloQueryResult<ResponseBranch>> {
    return this.apollo.query({
      query: gql`
        query getAllBrachOffice ($pagInput: FilteringInput!) {
          getAllBrachOffice(FilteringInput:$pagInput){
            items{
              ${outPutResult}
            }
            meta{
              itemCount
              totalItems
              itemsPerPage
              totalPages
              currentPage
            }
          }
        }
      `,
      variables: {
        pagInput,
      },
    });
  }

  getMerchantById(
    clientId: string,
    outPutResult: string
  ): Observable<ApolloQueryResult<ResponseMerchant>> {
    return this.apollo.query({
      query: gql`
        query getClient($clientId: String!){
          getClient(clientId: $clientId) {
            ${outPutResult}
          }
        }`,
      variables: {
        clientId,
      },
    });
  }

  getAllConfigMerchantById(
    configId?: string
  ): Observable<ApolloQueryResult<IResponseConfiguration>> {
    return this.apollo.query({
      query: gql`
        query GetAllConfigMerchantById($configId: String!) {
          getAllConfigMerchantById(configId: $configId) {
            message
            configurations {
              id
              nodeId
              configName
              configData
            }
          }
        }
      `,
      variables: {
        configId,
      },
    });
  }

  getAllBranchConfigByNodeId(
    idNode?: string
  ): Observable<ApolloQueryResult<IResponseBranchConfiguration>> {
    return this.apollo.query({
      query: gql`
        query getConfigByNode($idNode: String!) {
          getConfigByNode(idNode: $idNode)
        }
      `,
      variables: {
        idNode,
      },
    });
  }

  generateDeeplinksBookFile(
    input: GenerateDeeplinksBookFileInput
  ): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: gql`
        query generateDeeplinksBookFile(
          $input: GenerateDeeplinksBookFileInput!
        ) {
          generateDeeplinksBookFile(generateDeeplinksBookFileInput: $input) {
            base64File
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  uploadFileBranchs(
    file: File,
    merchant: string
  ): Observable<MutationResult<ResponseUploadFile>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createBranchOfficeFile($file: Upload!){
          createBranchOfficeFile (file: $file,  client: "${merchant}")
          {
            errorMessage{title,message},
            success
          }
        }`,
      variables: {
        file,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  getNodeMerchant(
    data: ParamsNodeByClientId
  ): Observable<ApolloQueryResult<ResponseNodeByClientId>> {
    return this.apollo.query({
      query: gql`
        query getNode($data: GetNodeByClientId!) {
          getNode(getNodeByClientId: $data) {
            clientId
            id
            nodeType
            status
          }
        }
      `,
      variables: {
        data,
      },
    });
  }

  createPdf(input: IDefaultPdf): Observable<MutationResult<ResponseCreatePdf>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createPdf($input: PdfGenerationInput!) {
          createPdf(createPdf: $input) {
            status
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  deactivateNode(id: number): Observable<MutationResult<ResponseCreatePos>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation deactivateNode($input: Node!) {
          deactivateNode(deactivateNodeInput: $input) {
            status
            message
          }
        }
      `,
      variables: {
        input: {
          id: id,
        },
      },
    });
  }

  updateConfiguration(
    input: NodeConfiguration
  ): Observable<MutationResult<NodeConfiguration>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateConfiguration($input: InputUpdateConfiguration!) {
          updateConfiguration(updateConfiguration: $input) {
            message
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  updateClient(
    input: InputUpdateClient
  ): Observable<MutationResult<ResponseMessage>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateClient($input: UpdateClientInput!) {
          updateClient(updateClient: $input) {
            status
            message
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  getBranchReportsReports(
    input: IQueryBranchReport,
    outPutResult: string
  ): Observable<ApolloQueryResult<IResponseBranchReports>> {
    return this.apollo.query({
      query: gql`
      query listBranchReports($input: ListBranchReportsInput!){
        listBranchReports(listReport:$input){
            items {
              ${outPutResult}
            }
            meta{
                totalItems
                itemCount
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
    `,
      variables: {
        input,
      },
    });
  }

  getMerchantGroup(
    input: IQueryGroupByClient
  ): Observable<ApolloQueryResult<IQueryGroupResult>> {
    return this.apollo.query({
      query: gql`
        query getCommercialGroupByClientId($input: ClientIdInput!) {
          getCommercialGroupByClientId(clientIdInput: $input) {
            nodeId
            configData {
              businessName
              identification
              comercialName
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  searchGroup(
    search: string
  ): Observable<ApolloQueryResult<ISearchGroupResult>> {
    return this.apollo.query({
      query: gql`
        query searchCommercialGroup($search: String!) {
          searchCommercialGroup(search: $search) {
            items {
              id
              nodeId
              businessName
              identification
              comercialName
            }
          }
        }
      `,
      variables: {
        search,
      },
    });
  }

  associateMerchantToGroup(
    input: IMutatioAssociateGroupToMerchant
  ): Observable<MutationResult<IMutatioAssociateGroupToMerchantResult>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation addCommercialGroupToClient(
          $input: AddCommercialGroupToClientInput!
        ) {
          addCommercialGroupToClient(addCommercialGroupToClientInput: $input) {
            id
            nodeType
            clientId
            status
          }
        }
      `,
      variables: {
        input,
      },
    });
  }
}
