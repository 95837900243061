import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { IFrameSide } from '../../../../shared/interfaces/frame-side.interface';
import { MerchantItem } from '../../../../shared/interfaces/query-merchants';
import { GraphqlMerchantService } from '../../../../services/graphql/graphql-merchant.service';
import { Branch } from '../../../../shared/interfaces/query-branchs';
import { clientRoute } from 'src/app/constants/routes';
import { FormGroup } from '@angular/forms';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { ViewQrSideComponent } from '../../components/view-qr-side/view-qr-side.component';
import { ConfigurationFlowCreationService } from 'src/app/services/config/merchant/configuration-flow-creation.service';
import { MerchantStateService } from 'src/app/services/state/merchant-state.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit {
  static existsBranchs() {
    throw new Error('Method not implemented.');
  }
  static merchant() {
    throw new Error('Method not implemented.');
  }
  static resultfields() {
    throw new Error('Method not implemented.');
  }
  static getInitial() {
    throw new Error('Method not implemented.');
  }
  relativeRoute = clientRoute;
  avatar: string = assetUrl('png/avatar_merchant.png');
  merchant: MerchantItem = {
    id: '0',
    identification: '',
    comercialName: '',
    businessName: '',
    isComplete: false,
    status: '',
    cifAccount: '',
    region: '',
  };
  branch: Branch | null | undefined = null;
  @ViewChild(ViewQrSideComponent) viewQrSide!: ViewQrSideComponent;
  load: boolean = true;
  showSide: boolean = false;
  display: boolean = false;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  existsBranchs = false;
  frameSide: IFrameSide = {
    title: 'Carga masiva de sucursales',
    view: 'upload',
  };
  dataQr: any;
  resultFields: string = `id,
  identification
  comercialName
  businessName
  isComplete
  status
  cifAccount`;

  showModalDeactivateNodes: boolean = false;
  headerModalText = '';
  textModalText = '';
  typeNodeToDeactivate: string = '';
  idNodeToDeactivate: number = 0;
  formConfigData!: IFormGroup;
  search!: FormGroup;

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configFlow: ConfigurationFlowCreationService,
    private merchantStateService: MerchantStateService
  ) {}

  ngOnInit(): void {
    this.getInitial();
  }

  async getInitial() {
    this.formConfigData = this.configFlow.getForm();
    this.search = this.formConfigData.search;

    const merchantId = this.activatedRoute.snapshot.params['merchantId'];

    if (merchantId) {
      try {
        const getMerchantByIdRequest =
          this.graphqlMerchantService.getMerchantById(
            merchantId,
            this.resultFields
          );
        this.merchant = (
          await lastValueFrom(getMerchantByIdRequest)
        ).data.getClient;
        this.merchantStateService.setMerchant(this.merchant);
        this.existsBranchs = this.merchant.isComplete;
        this.load = false;
      } catch (error) {
        this.load = false;
        this.router.navigate([`${clientRoute}/list`]);
      }
    }

    this.items = this.getTabs(merchantId);

    this.activatedRoute.queryParams.subscribe((params) => {
      const tab = params['tab'];
      let band = true;
      if (tab) {
        const index = this.items.findIndex((x) => x.id === tab);
        if (index >= 0) {
          band = false;
          this.activeItem = this.items[index];
        }
      }
      if (band) {
        this.router.navigate([`${clientRoute}/info`, merchantId], {
          queryParams: { tab: this.items[2].id },
        });
        this.activeItem = this.items[2];
      }
    });

    this.search.controls['identification'].setValue(
      this.merchant.identification
    );
    this.search.controls['cifCode'].setValue(this.merchant.cifAccount);
  }

  private redirectTab = (item, merchantId) => {
    this.router.navigate([`${clientRoute}/info`, merchantId], {
      queryParams: { tab: item.item.id },
    });
    this.activeItem = item.item;
  };

  private getTabs = (merchantId) => {
    return [
      {
        label: 'Perfil',
        command: (item) => this.redirectTab(item, merchantId),
        id: 'perfil',
      },
      {
        label: 'Información finaciera',
        id: 'financialInformation',
        command: (item) => this.redirectTab(item, merchantId),
      },
      {
        label: 'Sucursales',
        command: (item) => this.redirectTab(item, merchantId),
        id: 'branchs',
      },
      {
        label: 'Reportes programados',
        id: 'reportsProgram',
        command: (item) => this.redirectTab(item, merchantId),
      },
      {
        label: 'Grupo empresarial',
        command: (item) => this.redirectTab(item, merchantId),
        id: 'groups',
      },
    ];
  };

  reloadList() {
    window.location.reload();
  }

  showModal(event: { type: string; id: number }) {
    if (event.type === 'BrachOfficeList') {
      this.headerModalText = '¿Quieres eliminar esta sucursal?';
      this.textModalText =
        'Al eliminar la sucursal se desactivarán los QR’s de todas las cajas y el establecimiento no podrá recibir pagos.';
    }
    if (event.type === 'POS') {
      this.headerModalText = '¿Quieres eliminar esta caja?';
      this.textModalText =
        'Al eliminar se desactivará el QR actual y la caja no podrá recibir pagos.';
    }
    this.showModalDeactivateNodes = true;
    this.typeNodeToDeactivate = event.type;
    this.idNodeToDeactivate = event.id;
  }

  hideModal() {
    this.showModalDeactivateNodes = false;
  }

  closeSide() {
    this.showSide = false;
  }

  deactivadeNode() {
    this.load = true;
    this.graphqlMerchantService
      .deactivateNode(this.idNodeToDeactivate)
      .subscribe({
        next: () => {
          this.reloadList();
        },
        error: (_err) => {},
      });
  }

  openSide(frame: IFrameSide) {
    this.showSide = false;
    this.frameSide = frame;
    this.branch = frame.extra;
    setTimeout(() => {
      this.showSide = true;
      if (frame.view === 'viewQr') {
        this.viewQrSide.initConf();
      }
    }, 200);
  }
}
