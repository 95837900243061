import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import {
  ICatalogueSourceChannel,
} from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';

@Component({
  selector: 'app-card-source-support',
  templateUrl: './card-source-support.component.html',
})
export class CardSourceSupportComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  @Input() formCN019!: FormGroup;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = false;
  enableEdit: boolean = false;
  hasProvider: boolean = false;
  sourceChannels: Array<ICatalogueSourceChannel> = [];
  formConfigData!:IFormGroup;

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
    this.initFormCN019();

    const sourceChannelControl = this.formCN019.get('sourceChannel');
    if(sourceChannelControl) {
      sourceChannelControl.valueChanges.subscribe((value: string) => {
        const sourceChannel = this.sourceChannels.find(
          (item) => item.code === value
        );
        this.hasProvider = !!sourceChannel?.hasProvider;
      });
    }
  }

  initFormCN019() {
    this.formConfigData = this.configDataService.getForm()
    this.formCN019 = this.formConfigData.CN019;
    this.formCN019.enable();
  }
  async getDataCatalogs() {
    const sourceChannels$ = this.cataloguesService.getSourceChannels();
    
    forkJoin([sourceChannels$]).subscribe(
      ([sourceChannels]) => {
        this.sourceChannels = sourceChannels;
      }
    );
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      const sourceChannel = this.sourceChannels.find(
        (item) => item.code === this.formCN019.controls['sourceChannel']?.value
      );
      this.formCN019.get('sourceChannel')?.setValue(sourceChannel?.code);
      this.hasProvider = !!sourceChannel?.hasProvider;
      console.log(`Selected new source channel`, sourceChannel);
      this.formCN019.enable();
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN019.patchValue(this.formCN019.getRawValue());
    this.setStateReable();
  }

  submit() {
    this.loading = true;
    this.formCN019.markAllAsTouched();
    if (!this.formCN019.valid) {
      this.loading = false;
      return;
    }
    return Promise.allSettled([
      this.configDataService.updateConfiguration('CN019'),
    ])
      .then(() => {
        this.loading = false;
        this.enableEdit = false;
        this.setStateReable();
        this.emitToast();
      })
      .catch((err) => {
        console.error('An error occurred:', err);
      });
  }

  setStateReable() {
    const sourceChannel = this.sourceChannels.find(
      (item) => item.code === this.formCN019.controls['sourceChannel'].value
    );
    this.formCN019.get('sourceChannel')?.setValue(sourceChannel?.name);
    this.formCN019.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
