import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import {
  defaultCountry,
  optionsTypeNotificationGeneral,
} from 'src/app/constants/catalogos/mocks-catalogo';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import {
  ICatalogue,
  ICatalogueCanton,
} from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { DEFAULT_COUNTRY } from 'src/app/constants/merchant-constants';

@Component({
  selector: 'app-card-address',
  templateUrl: './card-address.component.html',
})
export class CardAddressComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  @Input() formCN004!: FormGroup;
  @Input() formCN005!: FormGroup;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  formCN004TMP!: FormGroup;
  formCN005TMP!: FormGroup;

  loading: boolean = false;
  enableEdit: boolean = false;

  regions: Array<ICatalogue> = [];
  provinces: Array<ICatalogue> = [];
  cantons: Array<ICatalogueCanton> = [];
  cantonsSelect: Array<ICatalogueCanton> = [];
  phoneCodes: Array<ICatalogue> = [];
  notifications: Array<ICatalogue> = [];

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
    this.cantonsSelect = [];
    this.formCN004.enable();
    this.initFormCN005();
  }

  initFormCN005() {
    this.formCN005.enable();
    this.formCN005.get('webhookEmail')?.disable();
    this.formCN005.get('webhookUrl')?.disable();
  }
  async getDataCatalogs() {
    this.formCN004TMP = _.cloneDeep(this.formCN004);
    this.formCN005TMP = _.cloneDeep(this.formCN005);
    const regions$ = this.cataloguesService.getRegions();
    const provinces$ = this.cataloguesService.getProvinces();
    const cantons$ = this.cataloguesService.getCantons();
    const phoneCodes$ = this.cataloguesService.getCountriesCode();

    forkJoin([regions$, provinces$, cantons$, phoneCodes$]).subscribe(
      ([regions, provinces, cantons, phoneCodes]) => {
        this.regions = regions;
        this.provinces = provinces;
        this.cantons = cantons;
        this.phoneCodes.push(
          phoneCodes.find((item) => item.code === '593') as ICatalogue
        );
        this.getCantons();
        this.formCN005.get('phoneCode')?.setValue(defaultCountry);
        if (this.isEditable && this.isFlowUpdate) {
          this.setStateReable();
        }
      }
    );
    this.notifications = optionsTypeNotificationGeneral;
  }

  onChangeSelect() {
    this.formCN004.get('canton')?.setValue('');
    this.getCantons();
  }
  getCantons() {
    this.cantonsSelect = [];
    if (this.formCN004.get('province')) {
      this.cantonsSelect = this.cantons.filter(
        (x) => +x.province === +this.formCN004.get('province')?.value?.code
      );
    }
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      const region = this.regions.find(
        (item) => item.code === this.formCN004TMP.controls['region']?.value
      );
      this.formCN004.get('region')?.setValue(region?.code);
      this.formCN004
        .get('province')
        ?.setValue(this.formCN004TMP.controls['province']?.value);
      this.formCN004
        .get('canton')
        ?.setValue(this.formCN004TMP.controls['canton']?.value);
      this.formCN004
        .get('country')
        ?.setValue(DEFAULT_COUNTRY);
      this.formCN005.get('phoneCode')?.setValue(defaultCountry);
      this.formCN004.enable();
      this.initFormCN005();
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN004.patchValue(this.formCN004TMP.getRawValue());
    this.formCN005.patchValue(this.formCN005TMP.getRawValue());
    this.formCN005.get('phoneCode')?.setValue(defaultCountry.name);
    this.setStateReable();
  }

  submit() {
    this.loading = true;
    this.formCN004.markAllAsTouched();
    this.formCN005.markAllAsTouched();
    if (!this.formCN004.valid || !this.formCN005.valid) {
      this.loading = false;
      return;
    }
    return Promise.allSettled([
      this.configDataService.updateCN004Configuration(),
      this.configDataService.updateCN005Configuration(),
    ])
      .then(() => {
        this.formCN004TMP = _.cloneDeep(this.formCN004);
        this.formCN005TMP = _.cloneDeep(this.formCN005);
        this.loading = false;
        this.enableEdit = false;
        this.setStateReable();
        this.emitToast();
      })
      .catch((err) => {
        console.error('An error occurred:', err);
      });
  }

  setStateReable() {
    const region = this.regions.find(
      (item) => item.code === this.formCN004.controls['region'].value
    );
    this.formCN004.get('region')?.setValue(region?.name);
    this.formCN004
      .get('province')
      ?.setValue(this.formCN004.controls['province'].value?.name);
    this.formCN004
      .get('canton')
      ?.setValue(this.formCN004.controls['canton'].value?.name);
    this.formCN005TMP.get('phoneCode')?.setValue(defaultCountry.name);
    this.formCN004.disable();
    this.formCN005.disable();
    this.formCN005TMP.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
