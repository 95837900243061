<app-exit-modal-report [displayModal]="displayModal" (onAccept)="onAccept()" (onHide)="onHide()" [exitModalTitle]="'¿Quieres abandonar el proceso?'" [exitModalDescription]="'Al seleccionar “Cancelar”, se eliminará toda la información 
registrada del reporte programado hasta el momento.'" [exitAcceptActionMessage]="'Sí, cancelar'" [exitCancelActionMessage]="'No, continuar editando'"></app-exit-modal-report>
<deuna-loading *ngIf="loading" [titulo]="'Creando negocio'" [subtitulo]="'Por favor, espera un momento'"></deuna-loading>
<form *ngIf="!loading" [formGroup]="formStepFrequency" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div class="text-style-back" [routerLink]="[relativeRoute+'/report/create/report-initial', merchantId]">
        Regresar
      </div>
    </div>
    <app-load-step [stepLoad]="100"></app-load-step>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Frecuencia y horario" subHeader="Programa con qué regularidad se enviará el reporte">
      <deuna-radio-button label="Frecuencia" styleClassItem="pt-2" styleClassContent="pb-4" (eventOnChange)="changeFrequency()" [values]="optionsFrequency" control="daysFrequency" [form]="formStepFrequency">
      </deuna-radio-button>
      <div class="grid" *ngIf="validationDay()">
        <deuna-select class="col-6" [options]="optionsDays" optionLabel="name" [form]="formStepFrequency" (onChangeSelect)="changeDays2()" [label]="labelDay" name="cutOffDay" control="cutOffDay" placeholder="Escoja una opción"></deuna-select>
        <div class="col-6">
          <label class="cutOffDaySecondLabel" for="disabled-input">Día de 2da quincena</label>
          <input id="disabled-input" type="text" [value]="formStepFrequency.controls['cutOffDaySecond'].value ?? ''" pInputText [disabled]="true" />
          <span *ngIf="formStepFrequency.controls['daysFrequency']?.value == '15'" class="ml-2 textMsg">Cuenta 15 días calendario desde el ‘Día de 1ra quincena’</span>
        </div>
      </div>
      <div class="grid">
        <deuna-select class="col-6" [options]="optionsHour" optionLabel="name" [form]="formStepFrequency" label="Hora de corte (El reporte llegará 1 hora después al negocio)" name="cutOffTime" control="cutOffTime" placeholder="Escoja una hora de corte"></deuna-select>
      </div>
    </app-card>
  </div>
  <div class="col-8 col-offset-2 pt-5">
    <app-card header="Destinatarios" subHeader="Escribe el correo de las personas a las que enviarás el reporte programado">
      <div formArrayName="mails">
        <div class="grid" *ngFor="let field of fields.controls; index as i">
          <div class="field col-5" formGroupName="{{i}}">
            <label for="mail{{i+1}}" class="block labelInput">Correo {{i+1}}</label>
            <input id="mail{{i+1}}" formControlName="mail" styleClass="p-inputtext" type="string" aria-describedby="input" pInputText [class]="
                            {
                                'ng-invalid': (isValidMails(i) && isDirty(i) || isTouched(i)),
                                'ng-dirty':(isValidMails(i) && isDirty(i) || isTouched(i))
                            }" />
            <small *ngIf="(isValidMails(i) && (isDirty(i) || isTouched(i)))" class="p-error block">
              Dato
              <span *ngIf="hasError(i,'required')"> obligatorio </span>
              <span *ngIf="hasError(i,'pattern')"> no válido </span>
            </small>
          </div>
          <div class="col-1" style="align-self: center" *ngIf="i != 0">
            <img [src]="trash" alt="trash" style="cursor: pointer" (click)="removeRow(i)" />
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <span (click)="addRow()" class="text-style-back" *ngIf="fields.controls.length < 10; else notAdd">
            + Agregar correo
          </span>
          <ng-template #notAdd>
            <span class="text-info">
              * Solo puedes enviar hasta a 10 correos
            </span>
          </ng-template>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-4 col-offset-6 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Programar reporte" type="submit"></deuna-button>
      </div>
    </div>
  </div>
</form>