<deuna-loading [titulo]="'Cargando información'" *ngIf="loadData" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<ng-container *ngIf="this.formReportData && !loadData">
    <div class="grid my-5">
        <div class='col-8 col-offset-2 mt-2'>
            <div class="mb-10">
                <div class="text-style-back" [routerLink]="[relativeRoute+'/info', this.merchantId]" [queryParams]="{ tab: 'reportsProgram' }">Regresar</div>
            </div>
        </div>


        <div class="col-8 col-offset-2 mt-5">
            <div class="grid">
                <div class="col-12 ">
                    <span class="headerReport"> <strong>{{reportName}}</strong></span>
                </div>
                <div class="col-12 ">
                    <span class="sub-headerReport">{{typeReport}} | {{levelReport}}</span>

                </div>
            </div>
            <span class="line"></span><br>
        </div>
        <div class='col-8 col-offset-2 mt-5' *ngIf="isUnifiedReport">
            <app-card-frequency [isEditable]="isEditable" [form]="formReportData"></app-card-frequency>
        </div>

        <div class='col-8 col-offset-2'>
            <app-card-recipient [isEditable]="isEditableEmails" [form]="formReportData.frequency" [merchantId]="merchantId" [maximumAllowedEmails]="maximumAllowedEmails">
            </app-card-recipient>
        </div>
    </div>
</ng-container>
